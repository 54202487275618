<template>
  <a-spin :spinning="isLoading">
    <sdPageHeader :title="$t.currentMovements" />
    <Main>
      <a-button
        type="primary"
        style="position: absolute;top: 30px;right: 30px"
        @click="paymentPage()"
      >
        <template #icon>
          <sdFeatherIcons
            size="12"
            type="send"
            style="color:#ffffff;margin-right: 2px;"
          />
        </template>
        &nbsp;{{ $t.makePayment }}
      </a-button>
      <a-row
        type="flex"
        justify="center"
        :gutter="16"
        align="top"
      >
        <a-col :span="24">
          <sdCards headless>
            <a-form
              layout="inline"
              class="text-right"
            >
              <a-form-item
                name="rangePicker"
                label="Tarih Seçimi"
              >
                <a-range-picker
                  :value="filterDate"
                  format="DD/MM/YYYY"
                  @change="filterDate = $event"
                />
              </a-form-item>
              <a-form-item>
                <a-button
                  type="primary"
                  @click="get()"
                >
                  Filtrele
                </a-button>
              </a-form-item>
            </a-form>
            <hr>
            <a-table
              :data-source="transactions"
              :columns="columns"
              :pagination="pagination"
              row-key="id"
              @change="handleTableChange"
            >
              <template #filterIcon="filtered">
                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
              </template>
            </a-table>
          </sdCards>
        </a-col>
      </a-row>
    </Main>
  </a-spin>
</template>

<script>
import { SearchOutlined } from '@ant-design/icons-vue'
import { defineComponent } from 'vue'
import { Main } from '@/view/styled'
import { prepareTableData } from '@/utility/utility'

export default defineComponent({
  components: {
    SearchOutlined,
    Main,
  },
  data() {
    return {
      transactions: [],
      isLoading: true,
      filterDate: '',
      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
        showSizeChanger: true,
        pageSizeOptions: ['10', '30', '50', '100', '200'],
      },
      columns: [
        {
          title: 'Açıklama',
          dataIndex: 'description',
        },
        {
          title: 'Miktar',
          dataIndex: 'amount',
        },
        {
          title: 'Son Bakiye',
          dataIndex: 'last_balance',
        },
        {
          title: 'İşlem Tipi',
          dataIndex: 'type_text',
          align: 'center',
        },
        {
          title: 'İşlem Zamanı',
          dataIndex: 'created_at',
        },
      ],
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    paymentPage() {
      var contractedState = localStorage.getItem('jwtContract')
      if (contractedState === '1') {
        this.$router.push({ name: 'T.PaymentContract.Page' })
      } else {
        this.$router.push({ name: 'C.Payment.Page' })
      }
    },
    get(tableData = undefined) {

      tableData = typeof tableData === 'undefined' ? prepareTableData(this.pagination) : tableData

      let startDate = ''
      let endDate = ''
      if (this.filterDate !== '') {
        startDate = this.filterDate[0].format('DD/MM/YYYY')
        endDate = this.filterDate[1].format('DD/MM/YYYY')
      }
      this.isLoading = true
      this.$restMethods.postForTSoftKargoWebApp('/get-receipt-customer', {
        startDate: startDate,
        endDate: endDate,
        ...tableData,
      }).then(response => {
        if ( ! response.hasError()) {
          const data = response.getData()

          this.transactions = data.transactions
          this.pagination = data.pagination

        }
        this.isLoading = false
      })
    },
    handleTableChange(page, filters, sorter) {
      this.get(prepareTableData(page, filters, sorter))
    },
  },
})
</script>
<style>
hr {
  border-color: #ededed;
  border-top: none;
  border-left: none;
  border-right: none;
}
</style>